<template>
    <div class="talron-separator"></div>
</template>

<script>
export default {
    data: function () {
        return {};
    }

}
</script>

<style scoped lang="scss">
.talron-separator {
    height: 2px;
    margin: 0 auto;
    background-color: var(--talron-separator-color);
}
</style>
